import React,{useContext} from 'react';
import { Link } from 'react-router-dom';

import { ApiContext } from '../context/ApiContext'
import { useHistory } from "react-router-dom";
import LinearProgress from '@mui/joy/LinearProgress'
import { profile } from 'console';
import { useTranslation } from 'react-i18next';
import DataDownload from '../components/DataDownload';



const OwningEach = (pageData, isfollow) => {
    const history = useHistory()
    const page = pageData
    const { profile, newUserIntPage,getUserInterest } =  useContext(
        ApiContext
    );
    

    const toOwnPage = () => {

        history.push("/owningpage/" + page.pageData.id)
    }
    const randnum = Math.floor(Math.random() * 29);
    const imageUrl = `https://ow6r85j5w096clt.site/media/image/background_${19}.jpg`;
    const { t } = useTranslation();

    return (
        <div className="col-md-5 col-sm-6 pe-2 ps-2 ">
            
        <div className="card w-100 p-0 hover-card shadow-xss border-light-md rounded-3 overflow-hidden me-2 ms-2 mb-3" >
        {!page.pageData.is_owned ? (
    <a className="fw-600 ms-auto font-xssss text-primary ps-3 pe-3 lh-32 text-uppercase rounded-3 ls-2 bg-gold-gradiant d-inline-block text-white shadow-sm position-absolute top---15 mb-5 ms-2 right-15">
        Your Book
    </a>
) : (
    <></>
)}


            
            {/* {value.feature ? <span className="font-xsssss fw-700 ps-3 pe-3 lh-32 text-uppercase rounded-3 ls-2 bg-primary-gradiant d-inline-block text-white position-absolute mt-3 ms-3 z-index-1">Featured</span> : ''} */}
            {
            page.pageData.img ?
            <div className="card-image w-100 mb-3"style={{ position: "relative", width: "100%", aspectRatio: "16/9", overflow: "hidden" }}>
                
                <span onClick={() => toOwnPage()}  className="position-relative d-block pointer"><img src={page.pageData.img.slice(0,4)==="http" ? page.pageData.img :process.env.REACT_APP_API_URL + page.pageData.img } alt="hotel" className="w-100 " style={{ width: "100%", height: "100%", objectFit: "cover" }} /></span>
            </div>:
            <div className="card-image w-100 mb-3"style={{ position: "relative", width: "100%", aspectRatio: "16/9", overflow: "hidden" }}>
                
                <span onClick={() => toOwnPage()} className="position-relative d-block pointer"><img src={imageUrl} alt="hotel" className="w-100" style={{ width: "100%", height: "100%", objectFit: "cover" }}/></span>
            </div>
            }
            {/* <div className="card-image w-100 mb-3">
                <span className="position-relative d-block pointer"><img src={page.pageData.img} alt="hotel" className="w-100" /></span>
            </div> */}
            <div className="card-body pt-0">
                {/* <i className="feather-bookmark font-md text-grey-500 position-absolute right-0 me-3"></i> */}
                {page.pageData.title && (
  <h4 className="fw-700 font-xss mt-0 lh-28 mb-0 pe-3 text-dark text-grey-900">
    {page.pageData.title.length > 15
      ? `${page.pageData.title.slice(0, 15)}...`
      : page.pageData.title}
  </h4>
)}

                <h6 className="font-xsssss text-grey-500 fw-600 mt-0 mb-2"> {page.pageData.created_on}</h6>

                <div className="card-body p-1 mb-0">
                {/* <div className="row">
                    <div className="col-12">
                        <h2 className="font-xss  lh-1 mt-2 text-grey-900 fw-300">{t('OwningEach1')} {pageData.pageData.rating}</h2>
                    </div>
                </div> */}
                <div className="row ">
                     <div className="col-lg-12">
                     {Array.isArray(page.pageData.files) && page.pageData.files.length > 0 && (
                    <div className="row">
                        {page.pageData.files.slice(0, 3).map((file) => (
                        <DataDownload
                            key={file.id}
                            fileId={file.id}
                            title={file.title}   // ダウンロード時の「ファイル名」に使いたいなら
                        />
                        ))}
                    </div>
                    )}

                                                        <h4 className="text-primary font-sm fw-700">
                            <span className="fw-500 mt-1 mb-0 ms-0 d-block text-grey-500 font-xsssss">{t('DataDownload3')}</span></h4>

    {/* Your Data がある場合（is_owned === true）→ S3 からダウンロード */}
    {/* {!page.pageData.is_owned ? (
        Array.isArray(page.pageData.files) && page.pageData.files.length > 0 && (
            <div className="row">
                {page.pageData.files.slice(0, 3).map((file) => (
                    <DataDownload key={file.id} fileId={file.id} title={file.title} />
                ))}
                                    <h4 className="text-primary font-sm fw-700">
                            <span className="fw-500 mt-1 mb-0 ms-0 d-block text-grey-500 font-xsssss">{t('DataDownload3')}</span></h4>
            </div>
        )
    ) : (
        (() => {
            // Your Data がない場合（is_owned === false）
            const hasFiles = Array.isArray(page.pageData.files) && page.pageData.files.length > 0;
            const hasUrls = Array.isArray(page.pageData.urls) && page.pageData.urls.length > 0;

            if (hasFiles) {
                // ファイルの mydata がすべて true か判定
                const allMyDataTrue = page.pageData.files.every(file => file.mydata === true);

                if (allMyDataTrue) {
                    // すべてのファイルの mydata が true → S3 からダウンロード
                    return (
                        <div className="row">
                            {page.pageData.files.slice(0, 3).map((file) => (
                                <DataDownload key={file.id} fileId={file.id} title={file.title} />
                            ))}
                                                <h4 className="text-primary font-sm fw-700">
                            <span className="fw-500 mt-1 mb-0 ms-0 d-block text-grey-500 font-xsssss">{t('DataDownload3')}</span></h4>
                        </div>
                        
                    );
                } else {
                    // 1つでも mydata === false のファイルがある場合
                    if (hasUrls) {
                        // URL がある → URL を表示
                        return (
                            <div className="row">
                                {page.pageData.urls.slice(0, 3).map((url, index) => (
                                    <DataDownload key={index} url={url} title={`Link ${index + 1}`} />
                                ))}

    <h4 className="text-primary font-sm fw-700">
                            <span className="fw-500 mt-1 mb-0 ms-0 d-block text-grey-500 font-xsssss">{t('DataDownload4')}</span></h4>
                            </div>
                        );
                    } else {
                        // URL もない → 「URLが取得できませんでした」のメッセージを表示
                        return (
                            <div className="row">
                                <p className="text-danger font-xssss fw-700">元データのURLが取得できませんでした</p>
                            </div>
                        );
                    }
                }
            } else {
                // ファイルがない場合
                if (hasUrls) {
                    return (
                        <div className="row">
                            {page.pageData.urls.slice(0, 3).map((url, index) => (
                                <DataDownload key={index} url={url} title={`Link ${index + 1}`} />
                            ))}
                        </div>
                    );
                } else {
                    return (
                        <div className="row">
                            <p className="text-danger font-xssss fw-700">URLが取得できませんでした</p>
                        </div>
                    );
                }
            }
        })()
    )} */}


                    </div>
                    </div>
                {/* <LinearProgress
                    color="info"
                    determinate
                    size="sm"
                    value= {pageData.pageData.rating}
                    variant="plain"
                    /> */}
                </div>
                <div className="clearfix"></div>
                {/* <span className="font-xsss fw-700 mt-0 pe-5 ls-2 lh-32 d-inline-block text-current float-left"><span className="font-xssss"></span>
                 </span> */}
                <Link onClick={() => toOwnPage()} className="position-absolute bottom-15 mb-5 ms-2 right-15"><i className="btn-round-sm bg-primary-gradiant text-white font-sm feather-chevron-right"></i></Link>
            </div>
        </div>
        </div>

    )
} 

export default OwningEach;