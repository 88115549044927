// import React, { useState, useContext} from 'react';
// import axios from 'axios';
// import { useTranslation } from 'react-i18next';
// import { SnackbarContext } from "../context/SnackbarContext";

// function DataDownload({ fileId, url, title }) {
//   const { t } = useTranslation();
//   const { newSnack } = useContext(SnackbarContext);

//   const handleDownload = async () => {
//     if (url) {
//       // URLがある場合は遷移
//       window.open(url, '_blank');
//     } else {
//       try {
//         const s3Response = await axios.post(
//           `${process.env.REACT_APP_API_URL}/api/user/get_s3_url/`,
//           { file_id: fileId }
//         );
//         let s3_url = s3Response.data.s3_url;

//         const fileResponse = await axios.post(
//           `${process.env.REACT_APP_API_URL}/api/user/download/`,
//           { file_id: fileId },
//           { responseType: 'blob' }
//         );

//         const mimeType = fileResponse.headers['content-type'] || 'application/octet-stream';
//         const blob = new Blob([fileResponse.data], { type: mimeType });
//         const downloadUrl = window.URL.createObjectURL(blob);
//         const link = document.createElement('a');
//         link.href = downloadUrl;
//         link.setAttribute('download', title);
//         document.body.appendChild(link);
//         link.click();
//         document.body.removeChild(link);

//         newSnack("success", t('DataDownload1'));
//       } catch (error) {
//         console.error("Download Error:", error);
//         newSnack("error", t('DataDownload2'));
//       }
//     }
//   };

//   return (
//     <span onClick={handleDownload}
//           className="pointer font-xsssss fw-800 ps-3 pe-3 lh-32 text-uppercase mt-2 rounded-3 ls-2 ms-2 bg-current ms-1 me-1 d-inline-block text-white shadow-sm w200">
//       {title.length > 15 ? `${title.slice(0, 15)}...` : title}
//     </span>
//   );
// }

// export default DataDownload;
import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { SnackbarContext } from "../context/SnackbarContext";

function DataDownload({ fileId, title }) {
  const [message, setMessage] = useState('');
  const { t } = useTranslation();
  const { newSnack } = useContext(SnackbarContext);

  const [token] = useState(localStorage.getItem('current-token'));


  const handleDownload = async () => {
    try {

      // **認証トークンを毎回取得**
      const token = localStorage.getItem('current-token'); 
      if (!token) {
        newSnack("error", t('Header14'));
        return;
      }

      // **S3 URL を取得するリクエスト**
      const s3Response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/user/get_s3_url/`, // 修正
        { file_id: fileId }
      );

      console.log("S3 URL Response:", s3Response);
      let s3_url = s3Response.data.s3_url;
      console.log("S3 URL:", s3_url);

      let filename = title; // デフォルトのファイル名（拡張子なし）
      if (s3_url) {
        filename = s3_url.split('/').pop();
        console.log("Extracted Filename:", filename);
      }

      // **ファイルを取得**
      const fileResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/user/download/`,
        { file_id: fileId },
        {
          responseType: 'blob',
          headers: {
            Authorization: `Token ${token}`
          }
        }
      );

      console.log("File Download Response:", fileResponse);

      // MIME タイプを取得
      const mimeType = fileResponse.headers['content-type'] || 'application/octet-stream';
      console.log("MIME Type:", mimeType);

      const blob = new Blob([fileResponse.data], { type: mimeType });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      newSnack("success", t('DataDownload1'));

    } catch (error) {
      console.error("Download Error:", error);
      newSnack("error", t('DataDownload2'));
    }
  };

  return (
    <span 
      onClick={handleDownload} 
      className="pointer font-xsssss fw-800 ps-3 pe-3 lh-32 text-uppercase mt-2 rounded-3 ls-2 ms-2 bg-current ms-1 me-1 d-inline-block text-white shadow-sm w200"
    >
      {title.length > 15 ? `${title.slice(0, 15)}...` : title} {/* 修正 */}
    </span>
  );
}

export default DataDownload;
