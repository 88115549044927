import React, { Fragment, useState, useEffect, useRef, useContext } from "react";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Appfooter from "../components/Appfooter";
import Popupchat from "../components/Popupchat";
import { Link } from 'react-router-dom';
import { ApiContext } from "../context/ApiContext";
import PostviewLike from "./PostviewLike";
import Lightbox from "react-image-lightbox";
import LoginComponent from "../components/LoginComponent";
import { useTranslation } from 'react-i18next';
import { makeStyles } from "@material-ui/core/styles";
import Createpurepost from "../components/CreatePurepost";
import { AddComment } from "@material-ui/icons";
import { SnackbarContext } from "../context/SnackbarContext";
import { Tooltip, Fab } from "@material-ui/core";
import { green } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  PostButton: {
    backgroundColor: green[400],
    "&:hover": {
      backgroundColor: green[200],
    },
  },
  PostButtonContainer: {
    position: "fixed",
    width: "100%",
    zIndex: "999",
    left: "600px",
    bottom: "70px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down('xs')]: {  // メディアクエリ
      left: '150px',
    },
  },
}));



// 他のインポートはそのまま

const LikedPosts = () => {
  const { profile, profiles, fetchLikedPostsWithScroll } = useContext(ApiContext);
  const classes = useStyles();
  const { t } = useTranslation();
  const { newSnack } = useContext(SnackbarContext);
  const [isOpen, setOpen] = useState(false);
  const [likedPosts, setLikedPosts] = useState([]);
  const [count, setCount] = useState(0);
  const loaderRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const fetchData = async () => {
    setIsLoading(true);
    const result = await fetchLikedPostsWithScroll(count);
    if (result && Array.isArray(result)) {  // resultが配列であるかを確認
        setLikedPosts(prevItems => count === 0 ? [...result, ...prevItems] : [...prevItems, ...result]);
    }
    setIsLoading(false);
};

  

  const handleObserver = (entities) => {
    const target = entities[0];
    if (target.isIntersecting && !isLoading) {
      setCount(prev => prev + 1);
    }
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    };
    const observer = new IntersectionObserver(handleObserver, options);
    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
      fetchData();
  }, [count, profile.id]);




  return (
    <Fragment>
      <Header />
      <Leftnav />

      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left ps-2 pe-2">
            <div className="row">
            <div className="col-xl-12 mb-3">
                    <div className="card w-100 border-0 p-0 pt-10 mt-5 bg-white shadow-xss rounded-xxl">
                    <div className="card-body d-block  pt-1 pb-0 ps-2 pe-2">
                                    {/* {profile.imgBackground ?
                                        <div className="row ps-2 pe-2">
                                            <div className="col-sm-12 p-1"><img src={profile.imgBackground} className="rounded-xxl w-100" alt="post" /></div>                                        
                                        </div>
                                        : <></>} */}
                                </div>
                                <div className='card-body p-0 position-relative'>
                                    { profile.img ?
                                    <figure className='avatar position-absolute w100 z-index-1' style={{top:'-40px',left:'30px'}}><img src={profile.img} alt="avater" className='float-right p-1 bg-white rounded-circle w-100'/></figure>:
                                    <figure className='avatar position-absolute w100 z-index-1' style={{top:'-40px',left:'30px'}}><img src="https://ow6r85j5w096clt.site/media/image/サラリーマン.jpeg" alt="avater" className='float-right p-1 bg-white rounded-circle w-100'/></figure>}
                                            {profile.nickName?
                                            <h4 className="fw-700 font-sm mt-2 mb-lg-5 mb-4 pl-15">
                                            {profile.nickName}
                                            <span className="fw-500 font-xssss text-grey-500 mt-1 mb-3 d-block">
                                            {profile.created_on}{t('Userpage6')}
                                            </span>
                                            </h4>:
                                            <h4 className="fw-700 font-sm mt-2 mb-lg-5 mb-4 pl-15">
                                            {t('Userpage1')}
                                            <span className="fw-500 font-xssss text-grey-500 mt-1 mb-3 d-block">
                                            XXXX-XX-XX{t('Userpage6')}
                                            </span>
                                            </h4>
                                            }

                                    <div className="position-absolute bottom-8 right-15">
                                    <Link to="/settings">
                                        <button className="pointer mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ls-3 d-inline-block rounded-xl  font-xsssss fw-700 ls-lg text-grey-600 d-flex">
                                        <i className="feather-settings text-grey-500 mt-0  font-lg"></i>
                                        </button>
                                    </Link>
                                    </div>
                                </div>

                                <div className='card-body d-block w-100 shadow-none mb-0 p-0 border-top-xs'>

                                    <ul className='nav nav-tabs h55 d-flex product-info-tab border-bottom-0 ps-4' id="pills-tab" role="tablist">
                                        <li className='list-inline-item me-5'><Link className='fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block' to="/userpage" data-toggle="tab">{t('Userpage2')}</Link></li>
                                        <li className="list-inline-item me-5"><Link className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" to="/userpagebook" data-toggle="tab">{t('Userpage7')}</Link></li>
                                        <li className="list-inline-item me-5 "><Link className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" to={`/followingpagelist`}data-toggle="tab">{t('Userpage3')}</Link></li>
                                        <li className="list-inline-item me-5"><Link className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block active" to="/likedposts" data-toggle="tab">{t('Userpage4')}</Link></li>

                                        {/* <li className="list-inline-item me-5"><Link className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" to="/following" data-toggle="tab">フォロー</Link></li>
                                        <li className="list-inline-item me-5"><Link className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" to="/followed" data-toggle="tab">フォロワー</Link></li> */}

                                        {/* <li className="active list-inline-item me-5"><Link className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" to="/posteddata" data-toggle="tab">{t('PostedData4')}</Link></li> */}

                                    </ul>

                                </div>

                             </div>
                            </div>
              <div className={classes.PostButtonContainer}>
                <Tooltip title="Post Comment" placement="top-start">
                  {profile.id ? (
                    <Fab
                      color="secondary"
                      className={classes.PostButton}
                      onClick={openDialog}
                    >
                      <AddComment />
                    </Fab>
                  ) : (
                    <Fab
                      color="secondary"
                      className={classes.PostButton}
                      onClick={() => newSnack("error", t('LikedPosts7'))}
                    >
                      <AddComment />
                    </Fab>
                  )}
                </Tooltip>
              </div>

                <Createpurepost
                  isVisible={isOpen}
                  profileData={profile}
                  handleClose={closeDialog}
                />

              <div className="col-xl-8 col-xxl-9 col-lg-8">
                {!profile.id ? (
                  <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3 me-3">
                    <LoginComponent />
                  </div>
                ) : (
                  likedPosts.map((post, index) => (
                    <PostviewLike
                    key={post.id}
                    postData={post}
                    profileData={post.userPostProfile}
                    reposting={post.reposting}
                    repostingProfileData={post.repostingProfile || null}
                    commentedCounts={post.commentedCounts} 
                    repostedTimes={post.repostedTimes}     
                    likedTimes={post.likedTimes}
                    monopage = {post.reviewToPage}  
                  />
                  ))
                )}
                <div ref={loaderRef} style={{ height: '20px', margin: '0 auto' }} className="mb-5"></div>
                {isLoading && (
                  <div className="card-body p-0 mb-3">
                    <div className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
                      <div className="snippet mt-2 ms-auto me-auto" data-title=".dot-typing">
                        <div className="stage">
                          <div className="dot-typing"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popupchat />
      <Appfooter />
    </Fragment>
  );
};

export default LikedPosts;
