import React, { Fragment, useState, useEffect, useRef } from "react";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Appfooter from "../components/Appfooter";
import RecommenduserEach from "../components/RecommenduserEach";
import { useContext } from "react";
import { ApiContext } from "../context/ApiContext";
import { useCookies } from "react-cookie";
import LoginComponent from "../components/LoginComponent";
import ProfileRecommender from "../components/ProfileRecommender";
import { useTranslation } from 'react-i18next';
import { makeStyles } from "@material-ui/core/styles";
import HowToModal from "../components/HowToModal";

const useStyles = makeStyles((theme) => ({
  line: {
    display: 'block',
    position: 'relative',
    '&::after': {
      background: 'linear-gradient(to right,#79ac78 0%,#79ac78 50%,#79ac78 100%)',
      content: '""',
      display: 'block',
      height: '2px',
      width: 0,
      transform: 'translateX(-50%)',
      transition: '2s cubic-bezier(0.22, 1, 0.36, 1)',
      position: 'absolute',
      bottom: 0,
      left: '50%',
    },
    '&.is-animated::after': {
      width: '100%',
    },
  },
}));

const Recommenduser = () => {
  const { profile, profilesWithScroll, searchProfiles } = useContext(ApiContext);
  const [profilesbyscroll, setProfilesbyscroll] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [noMoreData, setNoMoreData] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [showProfiles, setShowProfiles] = useState([]);

  const { t } = useTranslation();
  const loaderRef = useRef(null);
  const classes = useStyles();
  const [animate, setAnimate] = useState(false);  
  const [searchTerm, setSearchTerm] = useState("")

  const handleObserver = (entities) => {
    const target = entities[0];
    if (target.isIntersecting && !isLoading && !noMoreData) {
      setCount((prev) => prev + 1);
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    // ここでは検索APIは呼ばず、エンター送信まで待つ
  };
  

  const fetchData = async () => {
    setIsLoading(true);
    const result = await profilesWithScroll(count);

    if (result.length === 0) { 
        setNoMoreData(true);
    } else {
        setProfilesbyscroll(prevItems => [...prevItems, ...result]);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (searchTerm !== "") {
      // 検索モードの場合（以下はそのまま）
      if (count === 0) {
        setShowProfiles([]);
      }
      searchProfiles(searchTerm, count).then(result => {
        if (count === 0) {
          setShowProfiles(result.res);
        } else {
          setShowProfiles(prev => [...prev, ...result.res]);
        }
      });
    } else {
      // デフォルトモードの場合
      if (count === 0) {
        setProfilesbyscroll([]); // 追加：最初のページの場合は前の結果をクリア
      }
      fetchData();
    }
  }, [count, searchTerm]);
  
  

  useEffect(() => {
    setAnimate(true);  // コンポーネントがマウントされたときにアニメーションを開始
  }, []);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0
    };

    const observer = new IntersectionObserver(handleObserver, options);

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  const [isShowHowToModal, setShowHowToModal] = useState(false);
  const handleShowHowToModalClick = () => setShowHowToModal(true);
  const handleHowToModalClose = () => setShowHowToModal(false);

  useEffect(() => {
    const hasVisited = localStorage.getItem('hasVisited');
    setCount(0);
    setProfilesbyscroll([]);


    if (!hasVisited) {
      setShowHowToModal(true);
      localStorage.setItem('hasVisited', 'true');
    }
  }, []);

  const defaultProfiles = profilesbyscroll.map((profile, index) => (
    <RecommenduserEach key={index} profileeach={profile} />
  ));

  const listProfiles = showProfiles.map((profile) => (
    <RecommenduserEach key={profile.id} profileeach={profile} />
  ));
  const handleSearchSubmit = (e) => {
    e.preventDefault(); // ページリロード防止
    const trimmed = inputValue.trim();
    if (trimmed === "") {
      // 入力が空の場合はデフォルトモードへ切り替えるために、検索結果用状態をクリア
      setCount(0);
      setProfilesbyscroll([]);  // 追加：デフォルト結果をリセット
      setShowProfiles([]);      // 追加：検索結果もクリア
      setSearchTerm("");
    } else {
      setCount(0);             // 新しい検索の場合、count を 0 にリセット
      setShowProfiles([]);     // 追加：前回の検索結果をクリア
      setSearchTerm(trimmed);
    }
  };
  
  

  return (
    <Fragment>
      <Header />
      <Leftnav />

      <div className="main-content right-chat-active">
        <HowToModal isVisible={isShowHowToModal} handleClose={handleHowToModalClose} />
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row">
              <div className="col-xl-12">
                <div className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">
                  <h2 className="fw-700 mb-0 mt-0 font-md text-grey-900 d-flex align-items-center">
                  {t('Recommenduser4')}
                  <form className="pt-0 pb-0 ms-auto" onSubmit={handleSearchSubmit}>
  <div className="search-form-2 ms-1">
    <i className="ti-search font-xss"></i>
    <input
      type="text"
      value={inputValue}
      onChange={handleInputChange}
      className="form-control text-grey-500 mb-0 bg-greylight theme-dark-bg border-0"
      placeholder={t('Recommenduser3')}
    />
  </div>
</form>

                  </h2>
                </div>

                {inputValue ? (
                  <div className={`${classes.line}  ${animate ? "is-animated" : ""} font-xssss text-center text-current fw-600 mt-0 mb-2`}>
                    {t('Recommenduser1')}
                  </div>
                ) : (
                  <div className={`${classes.line}  ${animate ? "is-animated" : ""} font-xssss text-center text-current fw-600 mt-0 mb-2`}>
                    {t('Recommenduser2')}
                  </div>
                )}
               
                <div className="row ps-3 pe-3">
                {searchTerm !== "" ? ( 
    showProfiles.length > 0 ? showProfiles.map(profile => (
      <RecommenduserEach key={profile.id} profileeach={profile} />
    )) : <div>No search results</div>
  ) : (
    profilesbyscroll.map((profile, index) => (
      <RecommenduserEach key={index} profileeach={profile} />
    ))
  )}
                </div>
                <div ref={loaderRef} style={{ height: '20px', margin: '0 auto' }}></div>

                {isLoading && (
                  <div className="card-body p-0 mb-3">
                    <div className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
                      <div className="snippet mt-2 ms-auto me-auto" data-title=".dot-typing">
                        <div className="stage">
                          <div className="dot-typing"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="p-4 mb-3"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Appfooter />
    </Fragment>
  );
};

export default Recommenduser;
