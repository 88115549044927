import React from "react";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useContext } from "react";
import { ApiContext } from "../context/ApiContext";
import { makeStyles } from "@material-ui/core/styles";
import { useCookies } from "react-cookie";
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Darkbutton from "./Darkbutton";
import { SnackbarContext } from "../context/SnackbarContext";
import AdsModal from './AdsModal';
import DataModal from "./DataModal";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { Button } from "react-bootstrap";
import NotificationEach from "./NotificationEach";
import HowToModal from "./HowToModal";

const useStyles = makeStyles((theme) => {
  return {
    avatarContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginLeft: "1rem"
    },
    bodyStyle: {
      background: 'linear-gradient(-45deg,#79AC78,#B0D9B1)',
      backgroundSize: '400% 400%',
      animation: '$gradient 10s ease infinite',
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginLeft: "1rem"
      // height: '100vh'
    },
    "@keyframes gradient": {
      "0%": {
        backgroundPosition: '0% 50%'
      },
      "50%": {
        backgroundPosition: '100% 50%'
      },
      "100%": {
        backgroundPosition: '0% 50%'
      }
    },
    flexContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
  
    },  
  };
});

const Header = () => {
  // const [isOpen, setIsOpen] = useState(false)
  const [notiOpen, setNotiOpen] = useState("")
  

  const classes = useStyles();

  const { profile, getNotification, notificationCount, notifications } = useContext(ApiContext);
  const { newSnack } = useContext(SnackbarContext);
  const [isShowHowToModal, setShowHowToModal] = useState(false);
  const handleHowToModalClose = () => {
    setShowHowToModal(false);
  };

  const checkAndShowModal = async () => {
    // profile.idがない状態（未ログイン）で3時間経過しているか確認
    if (!profile.id) {
      const lastVisitedTime = localStorage.getItem('lastVisitedTime');
      const currentTime = Date.now();
      const threeHoursInMillis = 3 * 60 * 60 * 1000;

      if (!lastVisitedTime || currentTime - lastVisitedTime > threeHoursInMillis) {
        setShowHowToModal(true);
        localStorage.setItem('lastVisitedTime', currentTime);
      }
    }
  };

  useEffect(() => {
    const fetchProfileAndShowModal = async () => {
      if (profile) {
        await checkAndShowModal();
      }
    };

    fetchProfileAndShowModal();
  }, [profile]);



  const handleNotiOpen = () => {
    if (notiOpen === "show") {
      handleNotiClose();  // 既に開いている場合は閉じる
    } else {
      setNotiOpen("show");  // 開いていない場合は開く
    }
  };
  
  const handleNotiClose = () => {
    getNotification();
    setNotiOpen("");
  }

  useEffect(() => {
    // profile.idがある場合に通知を取得
    if (profile.id) {
      getNotification()
    }
  }, [profile.id]);

  const { t } = useTranslation();


  return (
    <div className="nav-header bg-white shadow-xs border-0">
      {!profile.id?
        <HowToModal
          isVisible={isShowHowToModal}
          handleClose={handleHowToModalClose}
        /> :
        <>
        
        </>}


      <div className="nav-top">
        <Link to="/recommend">
        
          <img src="/assets/images/tatami_logo_green.png" alt="logo" className="card p-0 h30 bg-transparent me-2  ms-2 border-0 "/>
          
          

          <span className=" fredoka-font ls-3 fw-600 text-current font-xxl logo-text mb-0">
          {t('Header4')}{" "}
          </span>{" "}

          <span className=" ms-3 mt-0 fw-600 text-current menu-icon  font-xsssss  mb-0">
          {t('Header23')}
          </span>
          <span className=" ms-2 mt-0 fw-600 text-current font-xssssss mob-menu mb-0">
          {t('Header23')}
          </span>

          {/* <div className="row text-center">
            <div>
          <img src="/assets/images/tatami_logo_green.png" alt="logo" className="card p-0 h30 bg-transparent me-2 ms-2 border-0 mob-menu"/>
          <span className="d-inline-block fredoka-font ls-3 fw-600 text-current font-xxl mob-menu logo-text mb-0 ">
          {t('Header4')}{" "}
          </span>{" "}
          </div>
          <span className=" ms-2 mt-0 fw-600 text-current font-xssssss mob-menu mb-0">
          {t('Header23')}
          </span>
          </div> */}

        </Link>
      </div>




{
        !profile.id ? (
          <span className={`pointer text-center ms-auto `}>
            <Link to='/login'>
              <button
                className={clsx(classes.bodyStyle, "btn menu-icon p-2 lh-24 ms-1 ls-3  rounded-xl btn-outline-current font-xsssss border-0 fw-700 ls-lg")}
              >

            
                <h2 className='fw-700 w75 mb-1 mt-1 menu-icon font-xsssss text-white-pink align-items-center'>{t('Header5')}</h2>
                </button>
              <button
                className={clsx(classes.bodyStyle, "btn pt-2 mob-menu pb-2 pe-0 ps-0 lh-24 ms-1 ls-3  rounded-xl btn-outline-current font-xsssss border-0 fw-700 ls-lg")}
              >
                  <h2 className='fw-700 w75 mb-1 mt-1 font-xsssss text-white-pink align-items-center'>{t('Header5')}</h2>
                </button>
            </Link>
          </span>
        ) : (
          <>
            <span className={`pointer ms-auto me-1 mt-1 d-flex`} id="dropdownMenu3" data-bs-toggle="dropdown" aria-expanded="false" onClick={handleNotiOpen}>
              <i className="feather-bell font-xl text-current"></i>

              {notificationCount > 0 && (
                <div className="d-flex">
                  <h4 className="fw-600 font-xssss text-warning">{notificationCount}</h4>
                  <i className="feather-plus font-xssss text-warning fw-600"></i>
                </div>
              )}
            </span>

            <span className="dot-count bg-warning"></span>

            <div className={`dropdown-menu p-4 right-0 rounded-xxl border-0 shadow-lg ${notiOpen}`} aria-labelledby="dropdownMenu3">
              <h4 className="fw-700 font-xss mb-4">{t('Header16')}</h4>

              {Array.isArray(notifications) && notifications.length > 0 ? (
  notifications.map((noti) => (
    <NotificationEach
      key={noti.id}  // ✅ 通知の ID を key にすることで React の再レンダリングを最適化
      notiData={noti}
      notiType={noti.type}
    />
  ))
) : (
  <h4 className="fw-600 font-xssss mb-4 text-dark">{t('Header17')}</h4>
)}


            </div>
          </>
        )
      }
      {profile.img ? (
        <div className={classes.avatarContainer}>
          <div>
            <Link to="/userpage" className="p-0 menu-icon">
              <img
                src={profile.img}
                alt="user"
                className="w40 mt-1 rounded-circle"
              />
            </Link>
          </div>
          {/* <div>{profile.nickName}</div> */}
        </div>
      ) : (
        <></>
      )}

      <nav className="navigation scroll-bar">
        <div className="container ps-0 pe-0">
          <div className="nav-content">
            <div className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1 mb-2 mt-2">
              <div className="nav-caption fw-600 font-xssss text-grey-500">
                <span></span>{t('Header7')}
              </div>
              <ul className="mb-1 top-content">
                <li className="logo d-none d-xl-block d-lg-block"></li>
                <li>
                  <Link to="/home" className="nav-content-bttn open-font">
                    {/* <i className="feather-trending-up btn-round-md bg-gold-gradiant me-3">
                      
                    </i> */}
                    <img src="/assets/images/tatami_logo_green_4.png" alt="logo" className="btn-round-md p-1 bg-white me-3 shadow-sm border-light"/>
                    <span>{t('Header8')}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/recommend" className="nav-content-bttn open-font">
                    <i className="feather-trending-up btn-round-md bg-orange me-3"></i>
                    <span>{t('Header10')}</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/recommenduser"
                    className="nav-content-bttn open-font"
                  >
                    <i className="feather-users btn-round-md bg-current me-3"></i>
                    <span>{t('Header9')}</span>
                  </Link>
                </li>

              </ul>
            </div>

            <div className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1">
              <div className="nav-caption fw-600 font-xssss text-grey-500">
                <span></span> {t('Header11')}
              </div>
              <ul className="mb-1">
                <li className="logo d-none d-xl-block d-lg-block"></li>
                <li>
                  <Link
                    to="/howto"
                    className="nav-content-bttn open-font h-auto pt-2 pb-2"
                  >
                    <i className="font-sm feather-info me-3 text-grey-500"></i>
                    <span>{t('Header19')}</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/owningpages"
                    className="nav-content-bttn open-font h-auto pt-2 pb-2"
                  >
                    <i className="font-sm feather-book me-3 text-grey-500"></i>
                    <span>{t('Header12')}</span>
                    {/* <span className="circle-count bg-warning mt-0">企業限定</span> */}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/adapplication"
                    className="nav-content-bttn open-font h-auto pt-2 pb-2"
                  >
                    <i className="font-sm feather-radio me-3 text-grey-500"></i>
                    <span>{t('Header15')}</span>
                    {/* <span className="circle-count bg-warning mt-0">企業限定</span> */}
                  </Link>
                </li>

                <li>
                  <Link
                    to="/settings"
                    className="nav-content-bttn open-font h-auto pt-2 pb-2"
                  >
                    <i className="font-sm feather-settings me-3 text-grey-500"></i>
                    <span>{t('Header13')}</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Notations-SCTA"
                    className="nav-content-bttn open-font h-auto pt-2 pb-2"
                  >
                    <h className=" fw-500 font-xxxs me-3 text-grey-500 text-decoration-underline pointer ">
                    {t('Header18')}</h>
                  </Link>
                </li>
                {/* <li><Link to="/settings" className="nav-content-bttn open-font h-auto pt-2 pb-2"><i className="font-sm feather-settings me-3 text-grey-500"></i><span>Post</span></Link></li> */}
              </ul>
            </div>
          </div>
        </div>
      </nav>

      <div className="app-header-search">
        <form className="search-form">
          <div className="form-group searchbox mb-0 border-0 p-1">
            <input
              type="text"
              className="form-control border-0"
              placeholder="Search..."
            />
            <i className="input-icon">
              <ion-icon
                name="search-outline"
                role="img"
                className="md hydrated"
                aria-label="search outline"
              ></ion-icon>
            </i>
            <span className="ms-1 mt-1 d-inline-block close searchbox-close">
              <i className="ti-close font-xs"></i>
            </span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Header;
